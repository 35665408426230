// theme constant
export const gridSpacing = 3;
// export const drawerWidth = 400;
export const drawerWidth = 400;
export const sidebarWidth = 400;
export const appDrawerWidth = 320;
export const navbarHeight = 100;
export const height = '93svh';
export const fontSize = 13;
export const tableHeight = 450;
//  export const ColorTheme = {
//     navBG:'#a2a8d3' ,
//     tabBG:'#e7eaf6',
//     sidebarBG:'#e7eaf6',
//     selectedTab:'#455d7a',
//     canvasBG:'#f7f7f7',
//     tabContentClr:'white'
//  }

export const lightTheme = {
  navBG: '#e3e3e3',
  //  tabBG:'#f7f7f7',
  tabBorder: '#f7f7f7',
  tabBG: '#e3e3e3',
  selectedTab: '#5c636e',
  sidebarBG: '#f7f7f7',
  sidebarInnerBG: '#f7f7f7',
  canvaSurroundsBG: '#f7f7f7',
  canvasBG: '#f7f7f7',
  sidebarContent: '#000',
  tabContentClr: '#000',
  title: '#000',
  logo: '#5FBDFF',
  iconColor: '#555555',
  leftbarBG: '#f5f5f5'
};

export const darkTheme = {
  navBG: '#000',
  //  tabBG:'#222831',
  tabBorder: '#222831',
  tabBG: '#000',
  selectedTab: 'whitesmoke',
  sidebarBG: '#000',
  sidebarInnerBG: '#000',
  canvaSurroundsBG: '#000',
  canvasBG: '#222831',
  sidebarContent: 'white',
  tabContentClr: 'white',
  title: 'whitesmoke',
  logo: '#5FBDFF',
  iconColor: 'white',
  leftbarBG: '#222831'
};
