/* eslint-disable */
export const products = [
    {
      Cybersecurity: ['TARA Tool', 'VSOC', 'HSM Stack', 'Code Signing', 'OTA & Data loggers'],
    },
    {
      'Functional Safety': [
        {
          name: 'FuSa for Semiconductors & Automotive Products',
          subOptions: ['ASIC Design', 'OEM'],
        },
        'Memory Partitioning: Multicore & BSW mapping to FuSa requirements',
      ],
    },
    {
      SDV: ['Virtualization ( Vayavya Labs )'],
    },
  ];
  
  export const cybersecurityServices = [
    {
      Cybersecurity: [
        {
          Automotive: [
            {
              name: 'Full-service partner for Cybersecurity Engineering',
              subOptions: ['TARA', 'Requirement Derivation', 'Cloud Security', 'OTA Security'],
            },
            {
              name: 'Cybersecurity: Hands on support at project level',
              subOptions: ['3rd Party HSM stack integration', 'Requirement Analysis'],
            },
            {
              name: 'Verification and Validation for vehicle Cybersecurity',
              subOptions: ['Fuzz Testing', 'PEN testing'],
            },
            {
              name: 'Post Quantum Cryptography',
              subOptions: ['Post Quantum Cryptography'],
            },
          ],
        },
        {
          SCADA: [
            {
              name: 'Security for Grid Systems',
              subOptions: ['Security for Grid System'],
            },
          ],
        },
      ],
    },
    {
      'Functional Safety': ['Analysis for Automotive Products', 'Analysis for Semiconductors'],
    },
    {
      'SDV - Virtualization, Customization': ['SDV - Virtualization, Customization'],
    },
  ];
  
  export const consulting = [
    { name: 'ISO 21434', subOptions: ['Projects'] },
    { name: 'ISO 26262', subOptions: ['Projects'] },
  ];
  
  export const academy = [
    'HSM Development on Virtual Platform',
    'TARA',
    'Company',
    'Design Process',
    'FuSa',
  ];
  
  export const contact = ['Business Enquiry', 'Hit Us with Your Work'];
  