import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  TextField,
  Slide
  // useTheme
} from '@mui/material';
import useStore from '../../Zustand/store';
import { shallow } from 'zustand/shallow';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { closeAll } from '../../store/slices/CurrentIdSlice';
import { setModelId } from '../../store/slices/PageSectionSlice';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const selector = (state) => ({
  create: state.createModel
});
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 300
//         }
//     }
// };

// function getStyles(name, nodes, theme) {
//     return {
//         fontWeight: nodes.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
//     };
// }
// const Properties = ['Confidentiality', 'Integrity', 'Authenticity', 'Authorization', 'Non-repudiation', 'Availability'];

export default function AddModel({ open, handleClose, getModels }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state?.userDetails);
  const { create } = useStore(selector, shallow);
  const notify = (message, status) => toast[status](message);
  // const theme = useTheme();
  const [templateDetails, setTemplateDetails] = React.useState({
    name: ''
    // properties: []
  });
  // const handleChange = (event) => {
  //     const {
  //         target: { value }
  //     } = event;
  //     setTemplateDetails({
  //         ...templateDetails,
  //         properties: typeof value === 'string' ? value.split(',') : value
  //     });
  // };

  const handleCreate = () => {
    const newModel = {
      ...templateDetails
    };

    create(newModel, userDetails?.username)
      .then((res) => {
        if (res) {
          // setTimeout(() => {
          notify(res.message ?? 'Model created successfully', 'success');
          navigate(`/Models/${res?.model_id}`);
          dispatch(setModelId(res?.model_id));
          dispatch(closeAll());
          // window.location.href = `/Modals/${id}`;
          getModels();
          handleClose();
          // }, 500);
        }
      })
      .catch((err) => {
        console.log('err', err);
        notify('Something Went Wrong', 'error');
      });
    setTemplateDetails((state) => ({
      ...state,
      name: ''
    }));
  };
  // console.log('templateDetails', templateDetails);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ fontSize: 18, fontFamily: 'Inter', pb:0 }}>{'Add Project'}</DialogTitle>
        <DialogTitle sx={{ fontSize: 14, fontFamily: 'italic', pt:0, pb:1 }}>{'Name of your project to create a new model.'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 1 }}>
              <TextField
                value={templateDetails?.name}
                id="outlined-basic"
                label="Name"
                variant="outlined"
                onChange={(e) => setTemplateDetails({ ...templateDetails, name: e.target.value })}
                sx={{
                  width: '300px'
                }}
              />
              {/* <FormControl sx={{ width: 350 }}>
                                <InputLabel notched id="demo-multiple-chip-label">
                                    Properties
                                </InputLabel>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={templateDetails.properties}
                                    onChange={handleChange}
                                    input={<OutlinedInput id="select-multiple-chip" label="Properties" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {Properties.map((name) => (
                                        <MenuItem key={name} value={name} style={getStyles(name, templateDetails.properties, theme)}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            cancel
          </Button>
          <Button variant="contained" onClick={handleCreate}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster position="top-right" reverseOrder={false} />
    </React.Fragment>
  );
}
