import React from 'react';
import LeftSection from './LeftSection';
import RightSection from './RightSection';

export default function Header1() {
  return (
    <>
      <LeftSection />
      <RightSection />
    </>
  );
}
